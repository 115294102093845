<template>
  <div class="common-layout">
      <div border=1>
      <br>
      <!--
      <iframe :src="htmlUrl" width="100%" height="500" border="1" allowfullscreen onload="scroll(0,0);" scrolling="no" style="min-width: 100%;" name="htmlwindow"></iframe>
      -->
      <div style="margin: 0 auto; width:100%; height:500px;">
        <object type="text/html" :data="htmlUrl"
                style="width:100%; height:100%; margin:1%;">
        </object>
      </div>
      </div>
      <br v-if="mustMaterialFlag">
      <div v-if="mustMaterialFlag" align="center" style="color:red;">講座内に、まだ受講が完了していない受講必須教材（かならず受講を完了する必要がある教材）が残っています。</div>
      <br>
      <div align="center">最後まで閲覧し終わったら、下記ボタンを押してこの教材の受講を完了してください。</div>
      <br>
      <table width="100%" style="margin-bottom:5px">

        <tr>
            <td align = "center" colspan="2">
              <!-- <div v-if="contentType === '01' && scsStudyStatus !== '1'">
                <el-button native-type="button" type="danger" plain @click="goEndTimeUpdate()">動画視聴後、このボタンを押してください。</el-button>
              </div>
              <div v-if="contentType === '03' && scsStudyStatus !== '1'">
                <el-button native-type="button" type="danger" plain @click="goEndTimeUpdate()">テキスト閲覧後、このボタンを押してください。</el-button>
              </div> -->
              <div v-if="contentType === '08' && scsStudyStatus !== '1'">
                <el-button size="small" native-type="button" type="danger" plain @click="goEndTimeUpdate()">ウェブページ閲覧後、このボタンを押してください。</el-button>
              </div>
              <div v-if="scsStudyStatus == '1'">
                <el-button size="small" native-type="button" type="danger" plain disabled>受講が完了しました。</el-button>
              </div>
              <br>
          </td>
        </tr>

        <tr>
          <td>
            <el-button size="small" native-type="button" class="studypack-button-primary" type="primary" plain @click="goBack" target="_blank"> 戻る </el-button>
          </td>
          <td align="right">
            <el-button size="small" v-if="mustFlag ==='1' && studentTeachingMaterialDto.teachingMaterialId !== null" native-type="button" class="studypack-button-primary" type="danger" plain @click="goNextTeachingMaterial()" target="_blank" :disabled="scsStudyStatus !== '1'"> 次の教材を受講する </el-button>
            <el-button size="small" v-if="mustFlag ==='0'" native-type="button" class="studypack-button-primary" type="danger" plain @click="goHome()" target="_blank"> トップページ へ </el-button>
            <el-button size="small" v-if="mustFlag ==='1' && studentTeachingMaterialDto.teachingMaterialId === null" native-type="button" class="studypack-button-primary" type="danger" plain @click="goHome()" target="_blank"> トップページ へ </el-button>
          </td>
        </tr>
      </table>
      <br>
  </div>
</template>

<script>
import request from '@/utils/request';
import Bread from '@/components/Bread.vue';
import { Notification } from 'element-ui';
export default {
  name: 'ShowHTML',
  components: {
    Bread,
    // CourseSearch,
  },
  data() {
    return {
      htmlUrl: '',
      courseId : '',
      subjectId :  '',
      unitId :  '',
      materialId : '',
      scsStudyStatus: '',
      contentType: '',
      mustFlag:'',
      studentTeachingMaterialDto: {},
      mustMaterialFlag: false
    }
  },
  created() {
    this.initData()
    this.bread = [
        {
          path: '',
          name: '戻る',
        },
      ];
  },
  watch:{
    $route(){
      if(this.$route.params.materialId){
        this.initData()
      }
    }
  },
  methods: {
    initData(){
      this.courseId = this.$route.params.courseId;
      this.subjectId = this.$route.params.subjectId;
      this.unitId = this.$route.params.unitId;
      this.materialId = this.$route.params.materialId;

      request('getTeachingMaterialShow', {
        courseId : this.$route.params.courseId,
        subjectId :  this.$route.params.subjectId,
        unitId :  this.$route.params.unitId,
        materialId :  this.$route.params.materialId,
      }).then((res) => {
        this.htmlUrl = res.contentUrl;
        this.scsStudyStatus = res.scsStudyStatus;
        this.contentType = res.contentType;
        this.mustFlag = res.mustFlag
        if(res.mustFlag ==='0' ){
          request('getTeachingMaterialByStudent').then((res) => {
            if(res.studentTeachingMaterialList.length > 0){
              const findResult = res.studentTeachingMaterialList.find(item => item.courseId===this.courseId)
              if(findResult !== undefined){
                this.mustMaterialFlag = true
              }
            }          
          });       
        }else{
          request('getTeachingMaterialByCourseList', {
            courseId : this.courseId ,
            subjectId :  this.subjectId ,
            unitId :  this.unitId,
            materialId :  this.materialId,
            testMoveFlag: false
          }).then((res) => {
            this.studentTeachingMaterialDto = res
          });
        }
      });
    },
    goBack() {
      history.go(-1);
    },
    goEndTimeUpdate() {
      request('endTimeUpdate', {
          courseId : this.courseId ,
          subjectId :  this.subjectId ,
          unitId :  this.unitId,
          materialId :  this.materialId,
        }).then((res) => {
          this.scsStudyStatus = res
        });
    },
    goNextTeachingMaterial(){
      const courseId = this.studentTeachingMaterialDto.courseId;
      const subjectId = this.studentTeachingMaterialDto.subjectId;
      const unitId = this.studentTeachingMaterialDto.unitId;
      const materialId = this.studentTeachingMaterialDto.teachingMaterialId;
      const testMoveFlag = this.studentTeachingMaterialDto.testMoveFlag;

      if(testMoveFlag){
        this.$router.push({
          path: `/test?courseId=${courseId}&subjectId=${subjectId}&unitId=${unitId}`,
          query: { materialId: materialId }
        }).catch(() => {});
      }else{
        if(this.studentTeachingMaterialDto.contentType === "01"){
          this.$router.push({
          path: `/showVideo/${courseId}/${subjectId}/${unitId}/${materialId}`,
        }).catch(() => {});
        }else if(this.studentTeachingMaterialDto.contentType === "03") {
          this.$router.push({
            path: `/showPDF/${courseId}/${subjectId}/${unitId}/${materialId}`,
          }).catch(() => {});
        }else if(this.studentTeachingMaterialDto.contentType === "08") {
          this.$router.push({
            path: `/showHtml/${courseId}/${subjectId}/${unitId}/${materialId}`,
          }).catch(() => {});
        }
      }
    },
    goHome(){
      this.$router.push({
        path: '/',
      }).catch(() => {});
    }
  }
}
// to the Top
window.scrollTo(0,0);

// document全体で右クリックを禁止する
document.oncontextmenu = function () {return false;}
// document全体でCTRL押すを禁止する
document.onkeydown = function(e) {
  var keycode;
  var ctrl;
  var shift;
  var prevent = null;

  // for something else IE
  if (e != null) {
    keycode = e.which;
    ctrl    = typeof e.modifiers == 'undefined' ? e.ctrlKey : e.modifiers & Event.CONTROL_MASK;
    shift   = typeof e.modifiers == 'undefined' ? e.shiftKey : e.modifiers & Event.SHIFT_MASK;
    prevent = function() {
      e.which = 0;
      e.preventDefault();
      e.stopPropagation();
    };
  }
  // for IE
  else {
    keycode = window.event.keyCode;
    ctrl    = window.event.ctrlKey;
    shift   = window.event.shiftKey;
    prevent = function() {
      window.event.keyCode = 0;
      window.event.returnValue = false;
      window.event.cancelBubble = true;
    };
  }

  // prevent
  var keychar = String.fromCharCode(keycode).toUpperCase();
  if (
      (ctrl && keychar == 'C') ||
      (ctrl && keychar == 'X') ||
      (ctrl && keychar == 'P')
  ) {
    prevent();
    return false;
  } else {
    return true;
  }
};

// const frames = window.frames; 
// for (let i = 0; i < frames.length; i++) {
//   frames[i].contentDocument.oncontextmenu = function(){return false; };
// }
//window.frames["htmlwindow"].contentDocument.oncontextmenu = function(){
// return false; 
//};
</script>

<style lang="less" scoped>
iframe {
  border: 2px black solid;
}

#app {
  height: 100%;

  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    overflow: hidden;

    .el-header {
      position: fixed;
      z-index: 10000;
      width: 100%;
      padding: 0;
      background: #d4ecf4;

      .app-header {
        position: relative;
        color: #fff;

        .app-header-logo {
          width: 200px;
          height: 100%;
          float: left;
          position: relative;
          background: url(../assets/logo.gif) no-repeat;
          background-size: contain;
          background-position: center;
        }

        .app-header-title {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 20px;
        }

        .app-header-right {
          float: right;
          height: 100%;
          display: flex;
          align-items: center;

          .app-header-badge {
            font-size: 20px;
          }
          & > div {
            padding: 0 10px;
            cursor: pointer;
            position: relative;
            transition: color .3s;
            font-size: 14px;

            &:hover {
              color: #ccc;
            }

            &:after {
              display: block;
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 1px;
              // background-color: #94CE58;
              background-color: #ddd;
            }

            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;

              &:after {
                display: none;
              }
            }

            span {
              font-size: 14px;
              vertical-align: middle;
            }
            .app-header-bell {
              margin-right: 10px;
              font-size: 20px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .el-main {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      margin-top: 60px;
      padding: 0;

      .app-main {
        flex: 1;
        height: 1024px;
      }
    }
    .el-footer {
      padding: 20px 0;
      display: flex;
      width: 100%;
      background: #d4ecf4;
      color: #fff;

      .app-footer-top {
        margin: 10px;
        text-align: center;
      }

      .app-footer-list-wrap {
        display: flex;
        justify-content: space-between;

        .app-footer-list {
          font-size: 18px;
          // flex: 1;

          .app-footer-list-group {
            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
            }

            .app-footer-list-title {
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 16px;
            }
            .app-footer-list-items {
              .app-footer-list-item {
                position: relative;
                padding-left: 12px;
                font-size: 13px;
                margin-bottom: 12px;

                &::before {
                  background: url(../assets/icon_aroow_right_white.svg) no-repeat;
                  background-position: center;
                  background-size: contain;
                  content: '';
                  height: 6px;
                  left: 0;
                  position: absolute;
                  top: 4px;
                  // top: 50%;
                  // transform: translateY(-50%);
                  width: 6px;
                }

                a {
                  color: inherit;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                    color: #ccc;
                  }
                }
              }
            }
          }
        }
      }

      .app-footer-copyright {
        margin-top: 20px;
        text-align: center;

        span {
          vertical-align: middle;
          font-size: 14px;
        }
        .el-link {
          color: #fff;

          &:hover {
            color: #409EFF;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  #app {
    .app-container {
      .el-header {
        height: 40px!important;
        .app-header {
          padding: 0 5px;
          .app-header-logo {
            width: 120px;
          }
          .app-header-title {
            font-size: 14px;
          }
        }
      }
      .el-main {
        margin-top: 40px;
      }
      .el-footer {
        .app-footer-list-wrap {
          flex-direction: column;
        }
      }
      .el-backtop {
        display: none;
      }
    }
  }
}
</style>
